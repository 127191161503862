// @import 'bootstrap-custom';
@import 'vars';

html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden;

    font-family: 'Futura Bk BT', Futura, sans-serif;
    color: inherit;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;

    button:focus {
        outline: none; /* overrides chrome default focus outline */
    }
}

#scale-harness {
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    background-color: white;
    color: white;

    #attractor {
        display: none;
        position: absolute;
        z-index: 200;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .touch-hints {
        display: block;
        position: absolute;
        z-index: 140;
        bottom: $gutter;
        left: 783px;
        width: 354px;
        height: 78px;
        opacity: 0;
    }
    img#touch-hint {
        mix-blend-mode: screen;
    }
    #touch-hint-background {
        background: rgba(0, 0, 0, 0.6);
        border-radius: $box-corners;
    }

    #still-there {
        display: block;
        position: absolute;
        z-index: 145;
        top: 449px;
        left: 690px;
        width: 540px;
        height: 132px;
        padding: 25px 0;
        border-radius: $box-corners;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        font-size: 60px;
        text-align: center;
        text-transform: uppercase;
        opacity: 0;

        small {
            display: block;
            text-transform: none;
            font-size: 38px;
        }
    }

    #map {
        display: block;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        canvas {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    #map-copyright-notice {
        display: none;
        position: absolute;
        z-index: 110;
        top: $gutter;
        left: $gutter;
        color: black;
        opacity: 0.6;
        font-size: 12px;

    }

    .side-menu {
        display: block;
        position: absolute;
        z-index: 150;
        bottom: $gutter;
        left: $gutter;
        width: 292px;

        button, .heading {
            position: relative;
            box-sizing: border-box;
            padding: 10px 22px 9px;
            margin-top: 2px;
            border: none;
            border-radius: $box-corners;
            width: 100%;

            opacity: 0;
            visibility: hidden;
        }

        button {
            background-color: $button-bg-up;
            font-family: 'Futura Bk BT', Futura, sans-serif;
            font-size: 20px;
            color: white;
            text-align: left;

            &.selected {
                background-color: $button-bg-down;
            }

            &[data-id="select_all"] {
                &:active {
                    background-color: $button-bg-down;
                }
            }
        }

        .heading {
            font-size: 22px;
            padding-top: 46px;
            background-color: $pink;
            text-transform: uppercase;
            z-index: 1;
            margin-top: 20px;
        }
    }

    .object-display {
        display: block;
        position: absolute;
        z-index: 160;
        top: $gutter;
        right: $gutter;
        width: 440px;

        opacity: 0;

        .bar {
            position: relative;
            box-sizing: border-box;
            padding: 10px $details-gutter;
            margin-top: 0;
            border: none;
            border-radius: $box-corners;
            width: 100%;
            overflow: hidden;

            &.pink {
                background-color: $pink;
            }

            &.top {
                padding-top: 74px;
                background: $pink url("../img/details-bar-pin.png") top 10px left $details-gutter no-repeat;
                background-size: 37px;

                .title {
                    display: block;
                    position: relative;
                    font-size: 24px;
                }

                .date {
                    display: block;
                    position: relative;
                    font-size: 18px;
                    margin-top: 8px;
                }
            }

            &.bottom {
                margin-top: 3px;
                height: 78px;

                button.close {
                    display: inline-block;
                    position: relative;
                    width: 46px;
                    height: 46px;
                    margin-top: 6px;

                    background: url("../img/close-button.png") center center no-repeat;
                    background-size: 46px;
                    border: none;
                }
            }

            &.middle {
                height: 812px;
                margin-top: 3px;
                padding: 0 0;
                background-color: $details-bg;
                overflow: hidden;

                .picture, .play {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 385px;
                    background: $details-picture-bg url("") center center no-repeat;
                    background-size: contain;
                }

                .play {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 385px;
                    background: url("../img/play.png") center center no-repeat;
                    background-size: 100px;
                    pointer-events: none;
                }

                .credit {
                    display: block;
                    position: relative;
                    padding: 0 $details-gutter;
                    margin-top: 9px;
                    opacity: .7;

                    font-size: 11px;
                    text-align: left;
                }

                .description {
                    display: block;
                    position: relative;
                    padding: 18px $details-gutter;
                    font-size: 19px;
                    line-height: 143%;
                    word-wrap: break-word;
                }
            }
        }
    }

    .overlay {
        display: block;
        position: absolute;
        z-index: 170;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $overlay-bg;
        text-align: center;

        opacity: 0;
        visibility: hidden;

        .object {
            display: block;
            position: relative;
            width: 100%;
            height: 92%;

            &.picture {
                background: url("") center center no-repeat;
                background-size: contain;
            }
        }

        .video-cover {
            pointer-events: none;
            pointer-events: all;
            touch-events: none;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: 500;
            top: 0;
            left: 0;
            opacity: 0;
        }

        .credit {
            display: block;
            position: relative;
            height: 2%;
            opacity: .5;
            font-size: 11px;
            padding-top: 14px;
        }

        button.close {
            display: inline-block;
            position: absolute;
            width: 46px;
            height: 46px;
            bottom: $gutter;
            right: $details-gutter;
            z-index: 1000;

            background: url("../img/close-button.png") center center no-repeat;
            background-size: 46px;
            border: none;
        }
    }
}
