

// colours (with the 'U' added for Dan's benefit)
$pink: #b30a4b;
$details-bg: rgba(0, 0, 0, .7);
$details-picture-bg: rgba(0, 0, 0, .15);

$overlay-bg: rgba(0, 0, 0, .9);

$button-bg-up: rgba(0, 0, 0, .6);
$button-bg-down: rgba(0, 0, 0, .85);

// layout
$gutter: 20px;
$box-corners: 3px;
$details-gutter: 26px;